<template>
  <search-visibility-block v-if="currentApp.id" :key="currentApp.id" :app-data="{id: currentApp.id}" />
</template>

<script>
import {mapGetters} from 'vuex';
import SearchVisibilityBlock from "@/components/AppDetailsComponent/components/SearchVisibility/index.vue";

export default {
  name: "Visibility",
  props: {
    isShowImprovementBlock: {
      type: Boolean,
      default: true,
    },
    isBlankRankingsLink: {
      type: Boolean,
      default: true,
    },
    currentKeywordCountryCode: {
      type: String,
    },
    appData: {
      type: Object,
    }
  },
  components: {
    SearchVisibilityBlock,
  },
  computed: {
    ...mapGetters([
      'currentApp',
    ]),
  },
}
</script>
